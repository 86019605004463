import React from 'react'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'
import favicon from '../../assets/icons/favicon.png'
import appleTouch from '../../assets/icons/apple-touch-icon.png'
import safariPinnedTab from '../../assets/icons/safari-pinned-tab.svg'
// import siteManifest from '../../assets/icons/site.webmanifest'

const Seo = ({ description }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  const metaDescription = description ? description : site.siteMetadata.description

  return (
    <Helmet>
      <title>{site.siteMetadata.title}</title>
      <meta name='description' content={metaDescription} />
      <meta name="language" content="pt-br" />
      <meta name="author" content={site.siteMetadata.author} />
      <meta name="subject" content="Mesas de Bilhar, Mesas de Carteado, Pebolim, Acessórios para bilhar, Consertos e Aluguéis" />
      <meta name="keywords" content="requinte básico, bilhar, mesas, poker, carteado, pebolim, ping pong, tenis de mesa, sinuca, cadeiras, poltronas, móveis, puff" />
      <meta name="subject" content="Mesas de Bilhar, Mesas de Carteado, Pebolim, Acessórios para bilhar" />
      <meta property='og:title' content={site.siteMetadata.title} />
      <meta property='og:locale' content="pt_BR" />
      <meta property='og:url' content="https://www.requintebasico.com.br/" />
      <meta property='og:site_name' content="Requinte Básico" />
      <meta property='og:description' content={metaDescription} />
      <meta property='og:type' content='website' />
      <meta name="HandheldFriendly" content="true" />
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <meta name="apple-mobile-web-app-title" content="Requinte Básico" />
      <link rel="apple-touch-icon" sizes="180x180" href={appleTouch} />
      <link rel="shortcut icon" type="image/png" href={favicon} />
      <link rel="mask-icon" href={safariPinnedTab} color="#000000" />
      <meta name="msapplication-TileColor" content="#000000" />
      <meta name="theme-color" content="#000000" />
    </Helmet>
  )
}

export default Seo
